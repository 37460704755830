<!--  隔离点统计  By阿旭 -->
<template>
  <div class="main">
    <div class="top">
      <p>隔离点统计</p>
    </div>
    <el-divider></el-divider>

    <div class="cantent-box">
      <!--<h3 class="subtitle">现存隔离数据</h3>-->

      <div v-if="extantIsolateList.length > 4">
        <IsolateTabel :td-list="extantIsolate" :table-data="extantIsolateList" />
        <div style="  display: flex;justify-content: center;">
          <BarGraph ref="BarGraphRef" style="max-width: 1000px; height: 400px; padding-bottom: 20px;" />
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;" v-else>
        <BarGraph ref="BarGraphRef" style="width: 50%; height: 400px;" item-id="extant-isolate" />
        <IsolateTabel :td-list="extantIsolate" :table-data="extantIsolateList" />
      </div>
    </div>
  </div>
</template>

<script>
import { statisticIsolationPoint } from "../../api/StatisticalAnalysis/dailySummaryStatistics";
import IsolateTabel from './modules/IsolateTabel';
import BarGraph from './modules/BarGraph';

export default {
  components: { IsolateTabel, BarGraph },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      extantIsolateList: [],
      extantIsolate: [
        { value: 1, label: "隔离点" },
        { value: 2, label: "总房间数" },
        { value: 3, label: "现存隔离人数" },
        { value: 4, label: "使用房间数" },
        { value: 5, label: "未使用房间数" },
        { value: 6, label: "可用房间数" }
      ],
      barDimensions: ['isolationPointName', '现存隔离人数', '使用房间数', '未使用房间数']
    };
  },
  created() {
    this.newadd();
  },
  methods: {
    async newadd() {
      let params = {
        orgCode: this.userdoctor.orgCode
      };
      let { data } = await statisticIsolationPoint({ params });
      if (data.code == 200) {
        this.extantIsolateList = data.data;
        let peopleCountArr = [];
        let roomUsedCountArr = [];
        let roomToUseCountArr = [];
        let xAxisData = [];
        let series = [];
        data.data.forEach(item => {
          peopleCountArr.push(item.peopleCount || 0)
          roomUsedCountArr.push(item.roomUsedCount || 0)
          roomToUseCountArr.push(item.roomToUseCount || 0)
          xAxisData.push(item.isolationPointName)
        })
        series.push({
          type: 'bar',
          name: '现存隔离人数',
          barWidth: '25px',
          data: peopleCountArr
        })
        series.push({
          type: 'bar',
          name: '使用房间数',
          barWidth: '25px',
          data: roomUsedCountArr
        })
        series.push({
          type: 'bar',
          name: '未使用房间数',
          barWidth: '25px',
          data: roomToUseCountArr
        })
        this.$nextTick(() => {
          this.$refs.BarGraphRef.getBarGraphList(xAxisData, series)
        })
      } else {
        this.$message.error(data.msg)
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
}
.top {
  height: 30px;
  font-size: 18px;
  line-height: 40px;
  font-weight: bold;
  color: #666666;
  display: flex;
  justify-content: space-between;
}

.cantent-box{
  margin-top: 10px;

  .subtitle {
    color: #333333;
    margin-bottom: 16px;
  }
}
</style>
